import axios from 'axios'
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  TOGGLE_LOADING,
  REFRESH_TOKEN,
  REQUEST_PASSWORD_CHANGE_SUCCESS,
  EXPIRED_PASSWORD,
  CLEAR_COMPANY_INFO,
  CLEAR_MONEYCORP,
  CLEAR_BENEFICIARIES,
  CLEAR_BENEFICIARY_DETAILS,
  CLEAR_CONVERSIONS,
  CLEAR_CONVERSION_DETAILS,
  CLEAR_PAYMENTS,
  CLEAR_BILLS,
  CLEAR_BILL_DETAILS,
  CLEAR_AUTOMATIONS,
  CLEAR_AUTOMATION_DETAILS,
  CLEAR_QUICKBOOKS,
  CLEAR_OVERVIEW,
  CLEAR_LOCKRATES,
  CLEAR_LOCKRATE_DETAILS,
  CLEAR_PINGPONG,
  CLEAR_BALANCES,
  CLEAR_ONBOARDING,
  RESET_BENEFICIARY_TEMPLATES,
  CLEAR_CAMBRIDGE,
  CLEAR_OFX,
  RECAPTCHA_FAIL,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SKIP_OFX_REAUTHENTICATE,
} from './types'
import {
  WORLDESK_URL,
  header_config,
  header_config_withCredentials,
} from '../api/config'
import { setUserEmail } from './onboarding'
import { notify } from './notifications'
import { setSessionExpiration } from './session'
import { push } from '@lagunovsky/redux-react-router'
import { LoginDataRes } from 'types/identity'

interface RecaptchaI {
  version: string
}

export const login =
  (email: string, password: string, recaptcha: RecaptchaI) =>
  async (dispatch: any) => {
    const body = JSON.stringify({ email, password, recaptcha })
    try {
      const res: LoginDataRes = await axios.post(
        WORLDESK_URL + '/identity/api/account/login',
        body,
        header_config_withCredentials
      )

      const {
        access_token,
        email_confirmed,
        phone_confirmed,
        password_expiration_days,
      } = res.data

      if (!email_confirmed && !phone_confirmed) {
        dispatch(setUserEmail(email))
        dispatch(push('/verifyemail'))
      }

      if (!phone_confirmed && email_confirmed) {
        dispatch(setUserEmail(email))
        dispatch(push('/verifyphone'))
      }

      if (access_token && email_confirmed && phone_confirmed) {
        if (password_expiration_days === 0) {
          dispatch(notify(`Password expired.`, 'error'))
          dispatch({ type: EXPIRED_PASSWORD, payload: res.data })
          dispatch(push('/expiredpassword'))
        } else {
          dispatch({ type: LOGIN_SUCCESS, payload: res.data })
          dispatch(getUserProfileAction())

          if (password_expiration_days > 1 && password_expiration_days < 8)
            dispatch(
              notify(
                `Password Expires in ${password_expiration_days} days. Please reset your password.`,
                'info'
              )
            )
        }
      }
    } catch (err: any) {
      if (err && err.response && err.response.data) {
        if (typeof err.response.data === 'string') {
          if (!err.response.data.includes('DOCTYPE')) {
            if (err.response.status === 500) {
              dispatch({
                type: LOGIN_FAIL,
                payload:
                  'We are unable to log you in, please try again in few minutes or contact Worldesk team',
              })
            } else {
              dispatch(notify(err.response.data, 'error'))
            }
          }
        }

        if (err.response.data.code) {
          if (err.response.data.code === 'sec:8') {
            dispatch({ type: RECAPTCHA_FAIL, payload: recaptcha.version })
          } else {
            dispatch({
              type: LOGIN_FAIL,
              payload: err.response.data.error,
            })
          }
        } else {
          if (err.response.status === 500) {
            dispatch({
              type: LOGIN_FAIL,
              payload:
                'We are unable to log you in, please try again in few minutes or contact Worldesk team',
            })
          } else if (err.response.status === 503) {
            dispatch({
              type: LOGIN_FAIL,
              payload:
                'We are unable to log you in, please try again in few minutes or contact Worldesk team',
            })
          } else if (err.response.status === 403) {
            if (typeof err.response.data !== 'string') {
              const { email_confirmed, phone_confirmed } = err.response.data

              if (
                'email_confirmed' in err.response.data &&
                'phone_confirmed' in err.response.data
              ) {
                if (!email_confirmed && !phone_confirmed) {
                  dispatch(setUserEmail(email))
                  dispatch(push('/verifyemail'))
                }
                if (!phone_confirmed && email_confirmed) {
                  dispatch(setUserEmail(email))
                  dispatch(push('/verifyphone'))
                }
              }
            } else {
              dispatch({
                type: LOGIN_FAIL,
                payload:
                  'We are unable to log you in, please try again in few minutes or contact Worldesk team',
              })
            }
          } else {
            dispatch({
              type: LOGIN_FAIL,
              payload:
                typeof err.response.data === 'string' ? err.response.data : '',
            })
          }
        }

        if (err.response.data === 'Password expired') {
          dispatch({ type: EXPIRED_PASSWORD, payload: email })
          dispatch(
            notify(
              `${err.response.data}. Please update your password.`,
              'error'
            )
          )
          dispatch(push('/password'))
        }
      } else {
        dispatch(notify('Login failed. Please contact administrator', 'error'))
        dispatch({ type: LOGIN_FAIL })
      }
    }
  }

export const logout = () => (dispatch: any) => {
  dispatch({ type: LOGOUT })
  dispatch({ type: CLEAR_ONBOARDING })
  dispatch({ type: CLEAR_COMPANY_INFO })
  dispatch({ type: CLEAR_MONEYCORP })
  dispatch({ type: CLEAR_PINGPONG })
  dispatch({ type: CLEAR_CAMBRIDGE })
  dispatch({ type: CLEAR_OFX })
  dispatch({ type: CLEAR_BENEFICIARY_DETAILS })
  dispatch({ type: CLEAR_BENEFICIARIES })
  dispatch({ type: CLEAR_CONVERSIONS })
  dispatch({ type: CLEAR_CONVERSION_DETAILS })
  dispatch({ type: CLEAR_PAYMENTS })
  dispatch({ type: CLEAR_BILLS })
  dispatch({ type: CLEAR_BILL_DETAILS })
  dispatch({ type: CLEAR_AUTOMATIONS })
  dispatch({ type: CLEAR_AUTOMATION_DETAILS })
  dispatch({ type: CLEAR_QUICKBOOKS })
  dispatch({ type: CLEAR_OVERVIEW })
  dispatch({ type: CLEAR_LOCKRATES })
  dispatch({ type: CLEAR_LOCKRATE_DETAILS })
  dispatch({ type: CLEAR_BALANCES })
  dispatch({ type: RESET_BENEFICIARY_TEMPLATES })
}

export const logoff = () => async (dispatch: any) => {
  localStorage.setItem('reauthenticateNeeded', '0')
  try {
    const res = await axios.post(
      WORLDESK_URL + '/identity/api/account/logout',
      header_config
    )
    console.log(res.data)
    dispatch(logout())
    dispatch(notify(`Logged out.`, 'success'))
  } catch (err: any) {
    console.log(err.response)
    dispatch(logout())
    dispatch(notify(`Logged out.`, 'success'))
  }
}

export const refreshToken =
  (email?: string, refresh_token?: string) => async (dispatch: any) => {
    const body = JSON.stringify({ email, refresh_token })

    try {
      const res = await axios.post(
        WORLDESK_URL + '/identity/api/account/refresh',
        body,
        header_config
      )
      dispatch({ type: REFRESH_TOKEN, payload: res.data })
    } catch (err) {
      //logout when you can't refresh
      dispatch(logoff())
      dispatch(setSessionExpiration(true))
    }
  }

export const changePassword =
  (old_password: string, new_password: string, expired?: boolean) =>
  async (dispatch: any) => {
    try {
      dispatch({ type: CHANGE_PASSWORD_REQUEST })

      const body = JSON.stringify({
        old_password,
        new_password,
      })

      const res = await axios.post(
        WORLDESK_URL + '/identity/api/account/change_password',
        body,
        header_config_withCredentials
      )

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.data,
      })

      if (expired) {
        dispatch(push('/'))
      } else {
        dispatch(logout())
      }

      dispatch(notify(`Your password successfully changed`, 'success'))
    } catch (err: any) {
      dispatch(notify('Password change failed. ', 'error'))

      if (err && err.response && err.response.data) {
        if (typeof err.response.data === 'string') {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            payload: err.response.data,
          })
        } else {
          if (err.response.data.code) {
            dispatch({
              type: CHANGE_PASSWORD_FAILURE,
              payload: err.response.data.error,
            })
          }
        }
      }
    }

    // axios(config)
    //   .then(function (response) {
    //     if (response && response.data) {
    //       if (expired) dispatch(push('/'))
    //       else dispatch(logout())

    //       dispatch(notify(`Your password successfully changed`, 'success'))
    //     } else {
    //       dispatch(notify('Password change failed. ', 'error'))
    //     }
    //     dispatch(setLoading(false))
    //   })
    //   .catch(function (err) {
    //     if (err?.response?.data?.error)
    //       dispatch(notify(err.response.data.error, 'error'))
    //     else dispatch(notify('Password change failed. ', 'error'))

    //     dispatch(setLoading(false))
    //   })
  }

export const requestPasswordReset =
  (email: string) => async (dispatch: any) => {
    const body = JSON.stringify({ email })
    try {
      await axios.post(
        WORLDESK_URL + '/identity/api/account/request_password_reset',
        body,
        header_config
      )

      dispatch({ type: REQUEST_PASSWORD_CHANGE_SUCCESS })
      dispatch(
        notify(
          `An email has been sent to ${email}. Please check your emails including your spam folder.`,
          'success'
        )
      )
    } catch (err: any) {
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.Email
      ) {
        err.response.data.errors.Email.forEach((err: any) =>
          dispatch(notify(err, 'error'))
        )
      } else {
        dispatch(notify('Password Request failed.', 'error'))
      }
      dispatch(setLoading(false))
    }
  }

export const resetPassword =
  (email: string, token: string, password: string) => async (dispatch: any) => {
    const body = JSON.stringify({
      email,
      token,
      password,
    })
    try {
      await axios.post(
        WORLDESK_URL + '/identity/api/account/reset_password',
        body,
        header_config
      )
      dispatch(notify(`Password updated`, 'success'))
      dispatch(setLoading(false))
      dispatch(push('/login'))
    } catch (err: any) {
      if (
        err.response &&
        err.response.data &&
        typeof err.response.data === 'string'
      )
        dispatch(notify(err.response.data, 'error'))
      else {
        dispatch(notify('Password reset failed', 'error'))
      }
      dispatch(setLoading(false))
    }
  }

export const getUserProfileAction = () => async (dispatch: any) => {
  try {
    dispatch({ type: FETCH_PROFILE_REQUEST })
    const res = await axios.get(
      WORLDESK_URL + '/identity/api/Profile',
      header_config
    )

    dispatch({
      type: FETCH_PROFILE_SUCCESS,
      payload: res.data,
    })
  } catch (err: any) {
    console.log(err.response)
    dispatch({ type: FETCH_PROFILE_FAILURE })
  }
}

export const editUserSettingsAction =
  ({ param, value }: { param: string; value: any }) =>
  async (dispatch: any) => {
    const body = JSON.stringify({
      [param]: value,
    })
    dispatch({ type: UPDATE_PROFILE_REQUEST, payload: param })

    try {
      const res = await axios.put(
        WORLDESK_URL + `/identity/api/profile`,
        body,
        header_config
      )
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: {
          [param]: value,
        },
      })
    } catch (err) {
      console.error(err)
      dispatch({ type: UPDATE_PROFILE_FAILURE })
    }
  }

export const setLoading = (isloading: boolean) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_LOADING,
    payload: isloading,
  })
}

export const skipOfxReauthenticateAction = () => (dispatch: any) => {
  dispatch({
    type: SKIP_OFX_REAUTHENTICATE,
  })
}
