// @ts-nocheck
import { useEffect, useState, Suspense, lazy } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import LoadingScreen from './components/loading/LoadingScreen'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Reauthenticate from './components/reauthenticate/Reauthenticate'
import isTokenValid from './utils/isTokenValid'
import setAuthToken from './utils/setAuthToken'
import { getCompanies, reconnectOnRefresh } from './actions/company'
import {
  getUserProfileAction,
  logout,
  skipOfxReauthenticateAction,
} from './actions/identity'
import { setInit } from './actions/init'
import ErrorFallBack from './components/errorboundary/ErrorFallBack'
import './App.css'
import getENV from './env'
import { recaptchaScript } from './utils/recaptchaScript'
import { useLocation } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'
import themeAuth from 'theme/themeAuth'
import { ColorModeScript } from '@chakra-ui/react'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { ThemeContext } from './themeContext'
import { saveState } from 'utils/persist'
// import Uploady from '@rpldy/uploady'
// import {
//   deleteApplicationAction,
//   getCorpayOnboardingRequiredFilesAction,
// } from 'actions/clientOnboarding'

if (getENV().WORLDESK_ENV !== 'production') {
  console.warn(getENV())
}

declare global {
  interface Window {
    grecaptcha: any
  }
}

const SignIn = lazy(() => import('./components/identity/SignIn'))
const Register = lazy(() => import('./components/onboarding/Register'))
const ForgotPassword = lazy(
  () => import('./components/identity/ForgotPassword')
)
const ExpiredPassword = lazy(
  () => import('./components/identity/ExpiredPassword')
)
const MainPage = lazy(() => import('./components/mainpage/MainPage'))
const EmailCode = lazy(() => import('./components/onboarding/EmailCode'))
const CreateCompany = lazy(
  () => import('./components/onboarding/CreateCompany')
)
const AddCompany = lazy(
  () => import('./components/mainpage/settings/company/AddCompany')
)
const PhoneCode = lazy(() => import('./components/onboarding/PhoneCode'))
const ResetPassword = lazy(() => import('./components/identity/ResetPassword'))
const TermsPage = lazy(() => import('./components/eula/TermsPage'))
const PrivacyPolicy = lazy(() => import('./components/eula/PrivacyPolicy'))
const Eula = lazy(() => import('./components/eula/EulaPage'))
const QuickBooksDisconnectApp = lazy(
  () => import('./components/mainpage/quickbooks/QuickBooksDisconnectApp')
)
const QuickBooksReconnect = lazy(
  () => import('./components/mainpage/quickbooks/QuickBooksReconnect')
)

const Alerts = lazy(() => import('components/mainpage/alerts/Alerts'))
const Bills = lazy(() => import('components/mainpage/bills/Bills'))
const Payments = lazy(() => import('components/mainpage/payments/Payments'))
const Conversions = lazy(
  () => import('components/mainpage/conversion/Conversions')
)
const Overview = lazy(() => import('components/mainpage/overview/Overview'))
const ClientOnboarding = lazy(
  () => import('components/mainpage/clientOnboarding/ClientOnboarding')
)
const LockRate = lazy(() => import('components/mainpage/lockrate/LockRate'))
const Wallet = lazy(() => import('components/mainpage/wallets/Wallet'))
const CreateBeneficiaries = lazy(
  () => import('components/mainpage/settings/beneficiaries/CreateBeneficiaries')
)
const BeneficiaryDetails = lazy(
  () => import('components/mainpage/settings/beneficiaries/BeneficiaryDetails')
)
const Beneficiaries = lazy(
  () => import('components/mainpage/settings/beneficiaries/Beneficiaries')
)
const ConversionDetails = lazy(
  () => import('components/mainpage/conversion/ConversionDetails')
)
const ConversionWizard = lazy(
  () => import('components/mainpage/conversion/ConversionWizard')
)
const CreatePayment = lazy(
  () => import('components/mainpage/payments/CreatePayment')
)
const PaymentDetails = lazy(
  () => import('components/mainpage/payments/PaymentDetails')
)
const PaymentAddBeneficiary = lazy(
  () => import('components/mainpage/payments/PaymentAddBeneficiary')
)
const LockRateWizard = lazy(
  () => import('components/mainpage/lockrate/LockRateWizard')
)
const LockRateDetails = lazy(
  () => import('components/mainpage/lockrate/LockRateDetails')
)
const AlertWizard = lazy(() => import('components/mainpage/alerts/AlertWizard'))
const AlertsDetails = lazy(
  () => import('components/mainpage/alerts/AlertsDetails')
)
const QuickBooksAccount = lazy(
  () => import('components/mainpage/quickbooks/QuickBooksAccount')
)
const QuickBooksConnect = lazy(
  () => import('components/mainpage/quickbooks/QuickBooksConnect')
)
const BillDetails = lazy(() => import('components/mainpage/bills/BillDetails'))
const ChangePassword = lazy(
  () => import('components/mainpage/../identity/ChangePassword')
)
const PingPongConnect = lazy(
  () => import('components/mainpage/pingpong/PingPongConnect')
)
const OfxConnect = lazy(() => import('components/mainpage/ofx/OfxConnect'))
const OverviewWithProvider = lazy(
  () => import('components/mainpage/overview/OverviewWithProvider')
)
const ReceivingAccounts = lazy(
  () => import('components/mainpage/pingpong/receiving/ReceivingAccounts')
)
const CreateReceivingAccounts = lazy(
  () => import('components/mainpage/pingpong/receiving/CreateReceivingAccounts')
)
const VirtualAccounts = lazy(
  () => import('components/mainpage/ofx/wallet/VirtualAccounts')
)
const Integrations = lazy(
  () => import('components/mainpage/settings/integrations/Integrations')
)
const Preferences = lazy(
  () => import('components/mainpage/settings/preferences/Preferences')
)
const Users = lazy(() => import('components/mainpage/settings/users/Users'))
const Company = lazy(
  () => import('components/mainpage/settings/company/Company')
)
const NotFound = lazy(() => import('components/mainpage/notfound/NotFound'))

function PrivateRoutes() {
  const { isAuthenticated } = useSelector((state: any) => state.identity)
  const location = useLocation()

  useEffect(() => {
    localStorage.setItem('last_path', location.pathname)
    localStorage.setItem('last_search', location.search)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (isAuthenticated) {
    return (
      <MainPage>
        <Outlet />
      </MainPage>
    )
  } else {
    return <Navigate to={'/login'} />
  }
}

const App = () => {
  const location = useLocation()
  const [currentTheme, setCurrentTheme] = useState(themeAuth)
  const dispatch = useDispatch()
  const { profile, isAuthenticated } = useSelector(
    (state: any) => state.identity
  )
  const { companies } = useSelector((state: any) => state.company)

  const dataDogEnabled = () => {
    if (
      (getENV().WORLDESK_ENV === 'production' ||
        getENV().WORLDESK_ENV === 'development') &&
      !window.location.host.includes('localhost')
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    // dispatch(deleteApplicationAction('421d5e08-4bda-43ff-b183-1ef3923dbf10'))
    localStorage.setItem('reauthenticateNeeded', 0)
    recaptchaScript()

    if (dataDogEnabled()) {
      datadogRum.init({
        applicationId: '09a7d002-b389-4d9f-8de8-ea73296482e8',
        clientToken: 'pub945f6818b2b8a9c9bdd0b7e4261b734d',
        site: 'datadoghq.com',
        service: 'worldesk',
        env: getENV().WORLDESK_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate:
          Number(getENV().DD_SESSION_REPLAY_SAMPLE_RATE) || 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
        enableExperimentalFeatures: ['clickmap'],
      })

      datadogLogs.init({
        clientToken: 'pub945f6818b2b8a9c9bdd0b7e4261b734d',
        service: 'worldesk.client',
        host: 'client',
        env: getENV().WORLDESK_ENV,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      })

      datadogRum.startSessionReplayRecording()
    }

    if (getENV().APPLICATION_CLIENT_ID === 'ofx.web_application') {
      document.title = 'OFX - Powered by Worldesk'
    }

    if (localStorage.token && isTokenValid()) {
      setAuthToken(localStorage.token)
      dispatch(getUserProfileAction())
    } else {
      dispatch(logout())
      dispatch(setInit(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAuthenticated && profile.isFetched) {
      let tourData = null
      const profileTourData = profile.data.tour_data
        ? JSON.parse(profile.data.tour_data)
        : null
      if (!profileTourData) {
        tourData = {
          payments: {
            createPaymentShown: false,
            paymentsShown: false,
          },
          transfers: {
            createTransferShown: false,
            transfersShown: false,
          },
          conversions: {
            createConversionShown: false,
            conversionsShown: false,
          },
        }
      } else {
        tourData = profileTourData
      }
      saveState(tourData, 'tour-guide-state')
      dispatch(getCompanies())
      if (dataDogEnabled()) {
        datadogRum.setUser({
          id: profile.data.sub,
          email: profile.data.email,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, profile.isFetched])

  useEffect(() => {
    if (profile.isFetched && !companies.isFetching && !companies.isFetched) {
      dispatch(reconnectOnRefresh())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.isFetched, companies])

  useEffect(() => {
    if (document.querySelector('#main-scrollarea')) {
      const elem = document.querySelector('#main-scrollarea > div')
      if (elem) {
        elem.scrollTo(0, 0)
      }
    }
    if (
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/verifyemail' ||
      location.pathname === '/verifyphone' ||
      location.pathname === '/forgotpassword' ||
      location.pathname === '/create-company' ||
      location.pathname === '/quickbooksdisconnect' ||
      location.pathname === '/profile/resetPassword' ||
      location.pathname === '/quickbooksreconnect'
    ) {
      setCurrentTheme(themeAuth)
    }
  }, [location])

  const onSkip = () => {
    dispatch(skipOfxReauthenticateAction())
    dispatch(getUserProfileAction())
  }

  return (
    <ThemeContext.Provider value={{ currentTheme, setCurrentTheme }}>
      <>
        <ColorModeScript
          initialColorMode={currentTheme.config.initialColorMode}
        />
        <ChakraProvider theme={currentTheme} resetCSS={true}>
          <ErrorBoundary FallbackComponent={ErrorFallBack}>
            <Suspense fallback={<LoadingScreen />}>
              <Routes>
                {/* <PrivateRoute component={MainPage} />  */}
                <Route element={<PrivateRoutes />}>
                  <Route exact path='/' element={<Overview />} />
                  <Route
                    exact
                    path='/client-onboarding'
                    element={<ClientOnboarding />}
                  />
                  <Route
                    exact
                    path='/overview'
                    element={<OverviewWithProvider />}
                  />
                  <Route exact path='/alerts' element={<Alerts />} />
                  <Route
                    exact
                    path='/pingpongconnect/account'
                    element={<PingPongConnect />}
                  />
                  <Route
                    exact
                    path='/ofxconnect/account'
                    element={<OfxConnect />}
                  />
                  <Route
                    exact
                    path='/accounts'
                    element={<ReceivingAccounts />}
                  />
                  <Route
                    exact
                    path='/accounts/create'
                    element={<CreateReceivingAccounts />}
                  />
                  <Route exact path='/bills' element={<Bills />} />
                  <Route exact path='/payments' element={<Payments />} />
                  <Route exact path='/conversions' element={<Conversions />} />
                  <Route exact path='/lockrate' element={<LockRate />} />
                  <Route exact path='/wallet' element={<Wallet />} />
                  <Route
                    exact
                    path='/virtualaccounts'
                    element={<VirtualAccounts />}
                  />
                  <Route
                    path='/settings'
                    element={<Navigate replace to='/settings/company' />}
                  />
                  <Route exact path='/settings/company' element={<Company />} />
                  <Route exact path='/add-company' element={<AddCompany />} />
                  <Route
                    exact
                    path='/settings/integrations'
                    element={<Integrations />}
                  />
                  <Route
                    exact
                    path='/settings/preferences'
                    element={<Preferences />}
                  />
                  <Route exact path='/settings/users' element={<Users />} />
                  <Route
                    exact
                    path='/QuickBooksConnect/account'
                    element={<QuickBooksAccount />}
                  />
                  <Route
                    exact
                    path='/quickbooksconnect'
                    element={<QuickBooksConnect />}
                  />
                  <Route
                    exact
                    path='/settings/change-password'
                    element={<ChangePassword />}
                  />
                  <Route
                    exact
                    path='/beneficiaries'
                    element={<Beneficiaries />}
                  />
                  <Route
                    exact
                    path='/beneficiaries/create'
                    element={<CreateBeneficiaries />}
                  />
                  <Route
                    exact
                    path='/beneficiaries/:id'
                    element={<BeneficiaryDetails />}
                  />
                  <Route
                    exact
                    path='/conversions/create'
                    element={<ConversionWizard />}
                  />
                  <Route
                    exact
                    path='/conversions/:id'
                    element={<ConversionDetails />}
                  />
                  <Route
                    exact
                    path='/payments/create'
                    element={<CreatePayment />}
                  />
                  <Route
                    exact
                    path='/payments/create/beneficiary'
                    element={<PaymentAddBeneficiary />}
                  />
                  <Route
                    exact
                    path='/payments/:id'
                    element={<PaymentDetails />}
                  />
                  <Route
                    exact
                    path='/lockrate/create'
                    element={<LockRateWizard />}
                  />
                  <Route
                    exact
                    path='/lockrate/:id'
                    element={<LockRateDetails />}
                  />
                  <Route
                    exact
                    path='/alerts/create'
                    element={<AlertWizard />}
                  />
                  <Route exact path='/alerts/:id' element={<AlertsDetails />} />
                  <Route exact path='/bills/:id' element={<BillDetails />} />
                  <Route path='*' element={<NotFound />} />
                </Route>
                <Route exact path='/login' element={<SignIn />} />
                <Route exact path='/eula' element={<Eula />} />
                <Route
                  exact
                  path='/forgotpassword'
                  element={<ForgotPassword />}
                />
                <Route
                  exact
                  path='/expiredpassword'
                  element={<ExpiredPassword />}
                />
                <Route
                  exact
                  path='/profile/resetPassword'
                  element={<ResetPassword />}
                />
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/verifyemail' element={<EmailCode />} />
                <Route
                  exact
                  path='/create-company'
                  element={<CreateCompany />}
                />
                <Route exact path='/verifyphone' element={<PhoneCode />} />
                <Route exact path='/terms' element={<TermsPage />} />
                <Route
                  exact
                  path='/privacy-policy'
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path='/quickbooksdisconnect'
                  element={<QuickBooksDisconnectApp />}
                />
                <Route
                  exact
                  path='/QuickBooksReconnect'
                  element={<QuickBooksReconnect />}
                />
              </Routes>
            </Suspense>
          </ErrorBoundary>
          <Reauthenticate onSkip={onSkip} />
        </ChakraProvider>
      </>
    </ThemeContext.Provider>
  )
}

export default App
